import React, { Component } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import { Headline, Markdown } from '../components';

class Impressum extends Component {
  render() {
    const { frontmatter, html } = this.props.data.file.childMarkdownRemark;
    return (
      <Layout location={this.props.location}>
        <Headline>{frontmatter.title}</Headline>
        <Markdown dangerouslySetInnerHTML={{ __html: html }} />
      </Layout>
    );
  }
}

export default Impressum;

export const query = graphql`
  query ImpressumQuery {
    file(relativePath: { eq: "impressum.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
        }
        html
      }
    }
  }
`;
